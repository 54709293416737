import axios2 from 'utils/axios2';

export const TRANSACTION_GET = 'TRANSACTION_GET';
export const TRANSACTION_LOAD = 'TRANSACTION_LOAD';
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';

/**
 * 
 * @param {{
 *  offset: number;
 *  limit: number;
 * }} query 
 * @param {{
 *  type?: 'credit' | 'debit';
 *  date?: Date;
 * }} filters 
 * @returns 
 */
export const getTransactions = (query, filters = {}) => dispatch => {
    dispatch({ type: TRANSACTION_LOAD });
    axios2.get('/transaction', { params: { ...query, ...filters } })
        .then(res => dispatch({ type: TRANSACTION_GET, filters, ...res.data.transactions }))
        .catch(error => dispatch({ type: TRANSACTION_ERROR, message: error.message }));
};