import * as actionTypes from '../actions';

const initialState = {
  products: [],
  filters: {},
  errored: null,
  loading: false,
  page: 0,
  rowCount: 10,
  total: 0
};

/**
 *
 * @returns {{
 *  products: any[];
 *  filters: {[key: string]: any[]};
 *  errored: string | null;
 *  loading: boolean;
 *  page: number;
 *  rowCount: number;
 *  total: number;
 * }}
 */
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_LOAD:
      return {
        ...state,
        loading: true
      };

    case actionTypes.PRODUCT_GET:
      return {
        products: action.items,
        filters: action.filters || {},
        errored: null,
        loading: false,
        page: Math.floor(action.offset / action.limit),
        rowCount: action.limit,
        total: action.total
      };

    case actionTypes.PRODUCT_CREATE:
      return {
        ...state,
        products: [action.product, ...state.products],
        errored: null,
        loading: false,
        total: state.total + 1
      };

    case actionTypes.PRODUCT_UPDATE:
      return {
        ...state,
        products: [
          ...state.products.map(p =>
            p.id === action.id ? { ...p, ...action.data } : p
          )
        ],
        errored: null,
        loading: false
      };

    case actionTypes.PRODUCT_DELETE:
      return {
        ...state,
        products: [...state.products.filter(p => p.id !== action.id)],
        errored: null,
        loading: false,
        total: state.total - 1
      };

    case actionTypes.PRODUCT_IMPORT:
      return {
        ...state,
        products: [...action.products, ...state.products].slice(
          0,
          state.rowCount
        ),
        errored: null,
        loading: false,
        page: 0,
        total: action.products.length + state.total
      };

    case actionTypes.PRODUCT_FAIL:
      return {
        ...state,
        errored: action.message,
        loading: false
      };

    default:
      return {
        ...state
      };
  }
};

export default productReducer;
