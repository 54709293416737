import * as actionTypes from 'actions';

const initialState = {
  customers: [],
  errored: null,
  loading: false,
  page: 0,
  rowCount: 10,
  total: 0
};

/**
 *
 * @returns {{
 *  customers: any[];
 *  errored: string | null;
 *  loading: boolean;
 *  page: number;
 *  rowCount: number;
 *  total: number;
 * }}
 */
const wsaleCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.W_CUSTOMER_LOAD:
      return {
        ...state,
        loading: true
      };

    case actionTypes.W_CUSTOMER_GET:
      return {
        customers: action.items,
        errored: null,
        loading: false,
        page: Math.floor(action.offset / action.limit),
        rowCount: action.limit,
        total: action.total
      };

    case actionTypes.W_CUSTOMER_ADD:
      return {
        ...state,
        customers: [action.customer, ...state.customers],
        errored: null,
        loading: false,
        total: state.total + 1
      };

    case actionTypes.W_CUSTOMER_EDIT:
      return {
        ...state,
        customers: [
          ...state.customers.map(v =>
            v.id === action.id ? { ...v, ...action.data } : v
          )
        ],
        errored: null,
        loading: false
      };

    case actionTypes.W_CUSTOMER_DELETE:
      return {
        ...state,
        customers: [...state.customers.filter(v => v.id !== action.id)],
        errored: null,
        loading: false,
        total: state.total - 1
      };

    case actionTypes.W_CUSTOMER_ERRORED:
      return {
        ...state,
        errored: action.message,
        loading: false
      };

    default:
      return {
        ...state
      };
  }
};

export default wsaleCustomerReducer;
