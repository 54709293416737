import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { Alert } from 'components';

const ErrorBoundary = props => {
  const dispatch = useDispatch();

  const handleError = () => {
    if (props.isRedux) {
      dispatch(props.accept());
    } else {
      props.accept();
    }
  };

  return (
    <Fragment>
      {props.children}
      <Snackbar
        open={!!props.error}
        autoHideDuration={6000}
        onClose={handleError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert variant="error" message={props.error} onClose={handleError} />
      </Snackbar>
    </Fragment>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.any.isRequired,
  accept: PropTypes.func.isRequired,
  isRedux: PropTypes.bool
};

export default ErrorBoundary;
