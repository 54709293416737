import axios2 from 'utils/axios2';

export const R_INVOICE_ADD = 'R_INVOICE_ADD';
export const R_INVOICE_GET = 'R_INVOICE_GET';
export const R_INVOICE_DELETE = 'R_INVOICE_DELETE';
export const R_INVOICE_LOAD = 'R_INVOICE_LOAD';
export const R_INVOICE_ERRORED = 'R_INVOICE_ERRORED';

/**
 *
 * @param {{
 * customerId: string;
 * discount: number;
 * gst: number;
 * docName: string;
 * ref: string;
 * products: {
 *  id: string;
 *  quantity: number;
 *  hsn: string;
 *  expDate: Date;
 *  discount: number;
 *  discountType: 'percent' | 'fixed';
 * }[];
 * }} data
 * @returns {*}
 */
export const addRInvoice = data => dispatch => {
  dispatch({ type: R_INVOICE_LOAD });
  axios2
    .post('/invoice', {...data, type: 'retail'})
    .then(res => dispatch({ type: R_INVOICE_ADD, invoice: res.data }))
    .catch(error => dispatch({ type: R_INVOICE_ERRORED, message: error.message }));
};

/**
 *
 * @param {{
 * offset: number;
 * limit: number;
 * }} query
 * @param {string | undefined} q
 * @returns {*}
 */
export const getRInvoices = (query, q) => dispatch => {
  dispatch({ type: R_INVOICE_LOAD });
  axios2
    .get('/invoice/retail', { params: { ...query, q } })
    .then(res => dispatch({ type: R_INVOICE_GET, ...res.data.invoices }))
    .catch(error => dispatch({ type: R_INVOICE_ERRORED, message: error.message }));
};

export const deleteRInvoice = id => dispatch => {
  dispatch({ type: R_INVOICE_LOAD });
  axios2
    .delete('/invoice/' + id)
    .then(() => dispatch({ type: R_INVOICE_DELETE, id }))
    .catch(error => dispatch({ type: R_INVOICE_ERRORED, message: error.message }));
};
