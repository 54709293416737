import axios2 from 'utils/axios2';

export const W_CUSTOMER_ADD = 'W_CUSTOMER_ADD';
export const W_CUSTOMER_GET = 'W_CUSTOMER_GET';
export const W_CUSTOMER_EDIT = 'W_CUSTOMER_EDIR';
export const W_CUSTOMER_DELETE = 'W_CUSTOMER_DELETE';
export const W_CUSTOMER_LOAD = 'W_CUSTOMER_LOAD';
export const W_CUSTOMER_ERRORED = 'W_CUSTOMER_ERRORED';

/**
 *
 * @param {{
 * name: string;
 * mobile: string;
 * gstin: string;
 * }} data
 * @returns {*}
 */
export const addWholesale = data => dispatch => {
  dispatch({ type: W_CUSTOMER_LOAD });
  axios2
    .post('/customer', {...data, type: 'wholesale'})
    .then(res => dispatch({ type: W_CUSTOMER_ADD, customer: res.data }))
    .catch(error => dispatch({ type: W_CUSTOMER_ERRORED, message: error.message }));
};

/**
 *
 * @param {{
 * offset: number;
 * limit: number;
 * }} query
 * @param {string | undefined} q
 * @returns {*}
 */
export const getWholesales = (query, q) => dispatch => {
  dispatch({ type: W_CUSTOMER_LOAD });
  axios2
    .get('/customer/wholesale', { params: { ...query, q } })
    .then(res => dispatch({ type: W_CUSTOMER_GET, ...res.data.customers }))
    .catch(error => dispatch({ type: W_CUSTOMER_ERRORED, message: error.message }));
};

/**
 *
 * @param {string} id
 * @param {{
 * name: string;
 * mobile: string;
 * }} data
 * @returns {*}
 */
export const editWholesale = (id, data) => dispatch => {
  dispatch({ type: W_CUSTOMER_LOAD });
  axios2
    .put('/customer/' + id, data)
    .then(() => dispatch({ type: W_CUSTOMER_EDIT, id, data }))
    .catch(error => dispatch({ type: W_CUSTOMER_ERRORED, message: error.message }));
};

export const deleteWholesale = id => dispatch => {
  dispatch({ type: W_CUSTOMER_LOAD });
  axios2
    .delete('/customer/' + id)
    .then(() => dispatch({ type: W_CUSTOMER_DELETE, id }))
    .catch(error => dispatch({ type: W_CUSTOMER_ERRORED, message: error.message }));
};
