import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Collapse,
  Divider,
  Drawer,
  Slider,
  TextField,
  Typography
} from '@material-ui/core';
import {
  ToggleButton,
  ToggleButtonGroup,
  Autocomplete
} from '@material-ui/lab';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import axios2 from 'utils/axios2';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const Filter = props => {
  const { open, onClose, onFilter, className, ...rest } = props;
  const [availableProductFilters, setAvailableProductFilters] = useState([]);

  const classes = useStyles();
  const productStore = useSelector(state => state.product);

  const initialValues = {
    paymentStatus: '',
    tag: '',
    tags: ['Full-Time'],
    amount: [1, 7],
    projectStatus: 'ended',
    customerName: '',
    customerType: 'freelancer',
    customerEmail: '',
    customerPhone: '',
    customerAge: ''
  };

  const [expandProject, setExpandProject] = useState(true);
  const [expandCustomer, setExpandCustomer] = useState(false);
  const [values, setValues] = useState({ ...initialValues });
  const [values2, setValues2] = useState({ ...productStore.filters });

  useEffect(() => {
    let mounted = true;

    axios2.get('/product/filters').then(res => {
      if (mounted) {
        setAvailableProductFilters(res.data);
      }
    });

    return () => (mounted = false);
  }, []);

  const handleClear = () => {
    setValues({ ...initialValues });
    setValues2({});
    onFilter && onFilter({ customer: { ...initialValues }, product: {} });
  };

  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    setValues(values => ({
      ...values,
      [field]: value
    }));
  };

  const handleFieldChange2 = (event, field, value) => {
    event.persist && event.persist();
    setValues2(values => ({
      ...values,
      [field]: value
    }));
  };

  const handleToggleProject = () => {
    setExpandProject(expandProject => !expandProject);
  };

  const handleToggleCustomer = () => {
    setExpandCustomer(expandCustomer => !expandCustomer);
  };

  const handleSubmit = event => {
    event.preventDefault();
    onFilter && onFilter({ customer: values, product: values2 });
  };

  /**
   *
   * @param {{
   * key: string;
   * title: string;
   * data: any;
   * type: 'multi-select' | 'single-select' | 'range';
   * }} filter
   */
  const mapFilterToComponent = filter => {
    switch (filter.type) {
      case 'multi-select':
        return (
          <div key={filter.key} className={classes.formGroup}>
            <Typography component="p" gutterBottom variant="overline">
              {filter.title}
            </Typography>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={filter.data}
              getOptionLabel={option => option}
              x
              filterSelectedOptions
              value={values2[filter.key] || []}
              onChange={(event, newValue) =>
                handleFieldChange2(event, filter.key, newValue)
              }
              renderInput={params => (
                <TextField
                  {...params}
                  className={classes.field}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  label="filterSelectedOptions"
                  placeholder={filter.title}
                />
              )}
            />
          </div>
        );

      case 'range':
        return (
          <div key={filter.key} className={classes.formGroup}>
            <Typography component="p" gutterBottom variant="overline">
              {filter.title}
            </Typography>
            <div className={classes.fieldGroup}>
              <Typography className={classes.minAmount} variant="body1">
                {filter.data.min}
              </Typography>
              <Slider
                className={classes.flexGrow}
                max={filter.data.max}
                min={filter.data.min}
                onChange={(event, value) =>
                  handleFieldChange2(event, filter.key, value)
                }
                value={values2[filter.key] || [0, 0]}
                valueLabelDisplay="auto"
              />
              <Typography className={classes.maxAmount} variant="body1">
                {filter.data.max}
              </Typography>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const customerAgeOption = ['18 - 30', '30 - 45', '50 - 60', '60+'];

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary">
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}>
        <div className={classes.header}>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.contentSection}>
            <div
              className={classes.contentSectionHeader}
              onClick={handleToggleProject}>
              <Typography variant="h5">Product</Typography>
              {expandProject ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
            <Divider />
            <Collapse in={expandProject}>
              <div className={classes.contentSectionContent}>
                {availableProductFilters.map(filter =>
                  mapFilterToComponent(filter)
                )}
              </div>
            </Collapse>
          </div>
          <div className={classes.contentSection}>
            <div
              className={classes.contentSectionHeader}
              onClick={handleToggleCustomer}>
              <Typography variant="h5">Customer</Typography>
              {expandCustomer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
            <Divider />
            <Collapse in={expandCustomer}>
              <div className={classes.contentSectionContent}>
                <div className={classes.contentSectionContent}>
                  <div className={classes.formGroup}>
                    <TextField
                      className={classes.field}
                      fullWidth
                      label="Customer name"
                      margin="dense"
                      name="customerName"
                      onChange={event =>
                        handleFieldChange(
                          event,
                          'customerName',
                          event.target.value
                        )
                      }
                      value={values.customerName}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <ToggleButtonGroup
                      exclusive
                      onChange={(event, value) =>
                        value && handleFieldChange(event, 'customerType', value)
                      }
                      size="small"
                      value={values.customerType}
                      variant="outlined">
                      <ToggleButton color="primary" value="projectOwner">
                        Project owner
                      </ToggleButton>
                      <ToggleButton value="freelancer">Freelancer</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      className={classes.field}
                      fullWidth
                      label="Email address"
                      margin="dense"
                      name="customerEmail"
                      onChange={event =>
                        handleFieldChange(
                          event,
                          'customerEmail',
                          event.target.value
                        )
                      }
                      value={values.customerEmail}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      className={classes.field}
                      fullWidth
                      label="Phone number"
                      margin="dense"
                      name="customerPhone"
                      onChange={event =>
                        handleFieldChange(
                          event,
                          'customerPhone',
                          event.target.value
                        )
                      }
                      value={values.customerPhone}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      className={classes.field}
                      fullWidth
                      label="Age"
                      margin="dense"
                      name="customerAge"
                      onChange={event =>
                        handleFieldChange(
                          event,
                          'customerAge',
                          event.target.value
                        )
                      }
                      select
                      // eslint-disable-next-line react/jsx-sort-props
                      SelectProps={{ native: true }}
                      value={values.customerAge}
                      variant="outlined">
                      <option disabled value="" />
                      {customerAgeOption.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={classes.actions}>
          <Button fullWidth onClick={handleClear} variant="contained">
            <DeleteIcon className={classes.buttonIcon} />
            Clear
          </Button>
          <Button color="primary" fullWidth type="submit" variant="contained">
            Apply filters
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Filter;
