import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useRouter from 'utils/useRouter';
import ErrorBoundary from 'components/ErrorBoundary';
import { acceptStoreError } from 'actions';

const StoreGuard = props => {
  const { plans, children } = props;

  const store = useSelector(state => state.store);
  const router = useRouter();

  useEffect(() => {
    if (!!store.store && !plans.includes(store.store.plan)) {
      router.history.push('/settings/subscription');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, store.store]);

  return (
    <ErrorBoundary error={store.error} accept={acceptStoreError} isRedux={true}>
      {children}
    </ErrorBoundary>
  );
};

StoreGuard.propTypes = {
  children: PropTypes.node,
  plans: PropTypes.array.isRequired
};

StoreGuard.defaultProps = {
  plans: []
};

export default StoreGuard;
