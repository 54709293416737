/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardDefaultView from './views/DashboardDefault';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/management/projects" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/print',
    component: ErrorLayout,
    routes: [
      {
        path: '/print/:id',
        exact: true,
        component: lazy(() => import('views/Print'))
      },
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/calendar',
        exact: true,
        component: lazy(() => import('views/Calendar'))
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView
      },
      {
        path: '/invoices/retail',
        exact: true,
        component: lazy(() => import('views/RInvoiceManagementList'))
      },
      {
        path: '/invoices/wholesale',
        exact: true,
        component: lazy(() => import('views/WInvoiceManagementList'))
      },
      {
        path: '/invoices/retail/create',
        exact: true,
        component: lazy(() => import('views/RInvoiceCreate'))
      },
      {
        path: '/invoices/wholesale/create',
        exact: true,
        component: lazy(() => import('views/WInvoiceCreate'))
      },
      {
        path: '/invoices/:id',
        exact: true,
        component: lazy(() => import('views/InvoiceDetails'))
      },
      {
        path: '/transactions',
        exact: true,
        component: lazy(() => import('views/TransactionsList'))
      },
      {
        path: '/management/customers/:id',
        exact: true,
        component: lazy(() => import('views/CustomerManagementDetails'))
      },
      {
        path: '/management/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CustomerManagementDetails'))
      },
      {
        path: '/management/vendors',
        exact: true,
        component: lazy(() => import('views/VendorManagementList'))
      },
      {
        path: '/management/vendors/:id',
        exact: true,
        component: lazy(() => import('views/VendorManagementDetails'))
      },
      {
        path: '/management/vendors/:id/:tab',
        exact: true,
        component: lazy(() => import('views/VendorManagementDetails'))
      },
      {
        path: '/management/projects',
        exact: true,
        component: lazy(() => import('views/ProjectManagementList'))
      },
      {
        path: '/management/products',
        exact: true,
        component: lazy(() => import('views/ProductManagementList'))
      },
      {
        path: '/management/orders',
        exact: true,
        component: lazy(() => import('views/OrderManagementList'))
      },
      {
        path: '/management/orders/create',
        exact: true,
        component: lazy(() => import('views/OrderCreate'))
      },
      {
        path: '/management/orders/:id',
        exact: true,
        component: lazy(() => import('views/OrderManagementDetails'))
      },
      {
        path: '/customers/retail',
        exact: true,
        component: lazy(() => import('views/RetailManagementList'))
      },
      {
        path: '/customers/wholesale',
        exact: true,
        component: lazy(() => import('views/WholesaleManagementList'))
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/employees',
        exact: true,
        component: lazy(() => import('views/Employees'))
      },
      {
        path: '/employees/:tab',
        exact: true,
        component: lazy(() => import('views/Employees'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
