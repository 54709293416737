import axios2 from 'utils/axios2';

export const ORDER_ADD = 'ORDER_ADD';
export const ORDER_GET = 'ORDER_GET';
export const ORDER_DELETE = 'ORDER_DELETE';
export const ORDER_LOAD = 'ORDER_LOAD';
export const ORDER_ERRORED = 'ORDER_ERRORED';

/**
 *
 * @param {{
 * vendorId: string;
 * id?: string;
 * billDate: Date;
 * status: 'unpaid' | 'paid';
 * products: {
 *  id: string;
 *  expDate: Date;
 *  mrp: number;
 *  quantity: number;
 *  free: number;
 *  purRate: number;
 *  discount: number;
 *  gst: number;
 *  hsn?: string;
 * }[];
 * }} data
 * @returns {*}
 */
export const addOrder = data => dispatch => {
  dispatch({ type: ORDER_LOAD });
  axios2
    .post('/bill', data)
    .then(res => dispatch({ type: ORDER_ADD, order: res.data }))
    .catch(error => dispatch({ type: ORDER_ERRORED, message: error.message }));
};

/**
 *
 * @param {{
 * offset: number;
 * limit: number;
 * }} query
 * @param {string | undefined} q
 * @returns {*}
 */
export const getOrders = (query, q) => dispatch => {
  dispatch({ type: ORDER_LOAD });
  axios2
    .get('/bill', { params: { ...query, q } })
    .then(res => dispatch({ type: ORDER_GET, ...res.data.bills }))
    .catch(error => dispatch({ type: ORDER_ERRORED, message: error.message }));
};

export const deleteOrder = id => dispatch => {
  dispatch({ type: ORDER_LOAD });
  axios2
    .delete('/bills/' + id)
    .then(() => dispatch({ type: ORDER_DELETE, id }))
    .catch(error => dispatch({ type: ORDER_ERRORED, message: error.message }));
};
