import axios2 from 'utils/axios2';

export const W_INVOICE_ADD = 'W_INVOICE_ADD';
export const W_INVOICE_GET = 'W_INVOICE_GET';
export const W_INVOICE_DELETE = 'W_INVOICE_DELETE';
export const W_INVOICE_LOAD = 'W_INVOICE_LOAD';
export const W_INVOICE_ERRORED = 'W_INVOICE_ERRORED';

/**
 *
 * @param {{
 * customerId: string;
 * status: 'paid' | 'unpaid';
 * products: {
 *  id: string;
 *  expDate: Date;
 *  quantity: number;
 *  free: number;
 *  purRate: number;
 *  discount: number;
 *  discountType: 'percent' | 'fixed';
 *  gst: number;
 *  hsn?: string;
 * }[];
 * }} data
 * @returns {*}
 */
export const addWInvoice = data => dispatch => {
  dispatch({ type: W_INVOICE_LOAD });
  axios2
    .post('/invoice', {...data, type: 'wholesale'})
    .then(res => dispatch({ type: W_INVOICE_ADD, invoice: res.data }))
    .catch(error => dispatch({ type: W_INVOICE_ERRORED, message: error.message }));
};

/**
 *
 * @param {{
 * offset: number;
 * limit: number;
 * }} query
 * @param {string | undefined} q
 * @returns {*}
 */
export const getWInvoices = (query, q) => dispatch => {
  dispatch({ type: W_INVOICE_LOAD });
  axios2
    .get('/invoice/wholesale', { params: { ...query, q } })
    .then(res => dispatch({ type: W_INVOICE_GET, ...res.data.invoices }))
    .catch(error => dispatch({ type: W_INVOICE_ERRORED, message: error.message }));
};

export const deleteWInvoice = id => dispatch => {
  dispatch({ type: W_INVOICE_LOAD });
  axios2
    .delete('/invoice/' + id)
    .then(() => dispatch({ type: W_INVOICE_DELETE, id }))
    .catch(error => dispatch({ type: W_INVOICE_ERRORED, message: error.message }));
};
