import axios2 from 'utils/axios2';

export const PRODUCT_IMPORT = 'PRODUCT_IMPORT';
export const PRODUCT_EXPORT = 'PRODUCT_EXPORT';
export const PRODUCT_GET = 'PRODUCT_GET';
export const PRODUCT_CREATE = 'PRODUCT_CREATE';
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_LOAD = 'PRODUCT_LOAD';
export const PRODUCT_FAIL = 'PRODUCT_FAIL';

/**
 *
 * @param {{
 * offset: number;
 * limit: number;
 * }} query
 * @param {{[key: string]: any[]}} filters
 * @param {string | undefined} q
 * @returns
 */
export const getProducts = (query, filters = {}, q) => dispatch => {
  dispatch({ type: PRODUCT_LOAD });
  const obj = {};
  Object.keys(filters).forEach(key => {
    obj[key] = filters[key].join(',');
  });
  axios2
    .get('/product', { params: { ...query, ...obj, q } })
    .then(res => dispatch({ type: PRODUCT_GET, filters, ...res.data.products }))
    .catch(error => dispatch({ type: PRODUCT_FAIL, message: error.message }));
};

/**
 *
 * @param {{
 * name: string;
 * company: string;
 * unit: string;
 * stock: number;
 * cost: number;
 * mrp: number;
 * purRate: number;
 * rate: number;
 * gst: number;
 * wholesaleDisc: number;
 * retailDisc: number;
 * }} data
 * @returns {*}
 */
export const createProduct = data => dispatch => {
  dispatch({ type: PRODUCT_LOAD });
  axios2
    .post('/product', data)
    .then(res => dispatch({ type: PRODUCT_CREATE, product: res.data }))
    .catch(error => dispatch({ type: PRODUCT_FAIL, message: error.message }));
};

/**
 *
 * @param {string} id
 * @param {{
 * name: string;
 * company: string;
 * unit: string;
 * stock: number;
 * cost: number;
 * mrp: number;
 * purRate: number;
 * rate: number;
 * gst: number;
 * wholesaleDisc: number;
 * retailDisc: number;
 * }} data
 * @returns {*}
 */
export const updateProduct = (id, data) => dispatch => {
  dispatch({ type: PRODUCT_LOAD });
  axios2
    .put('/product/' + id, data)
    .then(() => dispatch({ type: PRODUCT_UPDATE, data, id }))
    .catch(error => dispatch({ type: PRODUCT_FAIL, message: error.message }));
};

export const deleteProduct = id => dispatch => {
  dispatch({ type: PRODUCT_LOAD });
  axios2
    .delete('/product/' + id)
    .then(() => dispatch({ type: PRODUCT_DELETE, id }))
    .catch(error => dispatch({ type: PRODUCT_FAIL, message: error.message }));
};

/**
 *
 * @param {Blob} file
 * @returns {*}
 */
export const importProducts = file => dispatch => {
  dispatch({ type: PRODUCT_LOAD });
  const formData = new FormData();
  formData.append('file', file);
  axios2
    .post('/product/import', formData)
    .then(res => dispatch({ type: PRODUCT_IMPORT, products: res.data }))
    .catch(error => dispatch({ type: PRODUCT_FAIL, message: error.message }));
};
