import * as actionTypes from 'actions';

const initialState = {
  invoices: [],
  errored: null,
  loading: false,
  page: 0,
  rowCount: 10,
  total: 0
};

/**
 *
 * @returns {{
 *  invoices: any[];
 *  errored: string | null;
 *  loading: boolean;
 *  page: number;
 *  rowCount: number;
 *  total: number;
 * }}
 */
const wsaleInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.W_INVOICE_LOAD:
      return {
        ...state,
        loading: true
      };

    case actionTypes.W_INVOICE_GET:
      return {
        invoices: action.items,
        errored: null,
        loading: false,
        page: Math.floor(action.offset / action.limit),
        rowCount: action.limit,
        total: action.total
      };

    case actionTypes.W_INVOICE_ADD:
      return {
        ...state,
        invoices: [action.invoice, ...state.invoices],
        errored: null,
        loading: false,
        total: state.total + 1
      };

    case actionTypes.W_INVOICE_DELETE:
      return {
        ...state,
        invoices: [...state.invoices.filter(o => o.id !== action.id)],
        errored: null,
        loading: false,
        total: state.total - 1
      };

    case actionTypes.W_INVOICE_ERRORED:
      return {
        ...state,
        errored: action.message,
        loading: false
      };

    default:
      return {
        ...state
      };
  }
};

export default wsaleInvoiceReducer;
