import * as actionTypes from 'actions';

const initialState = {
  orders: [],
  errored: null,
  loading: false,
  page: 0,
  rowCount: 10,
  total: 0
};

/**
 *
 * @returns {{
 *  orders: any[];
 *  errored: string | null;
 *  loading: boolean;
 *  page: number;
 *  rowCount: number;
 *  total: number;
 * }}
 */
const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ORDER_LOAD:
      return {
        ...state,
        loading: true
      };

    case actionTypes.ORDER_GET:
      return {
        orders: action.items,
        errored: null,
        loading: false,
        page: Math.floor(action.offset / action.limit),
        rowCount: action.limit,
        total: action.total
      };

    case actionTypes.ORDER_ADD:
      return {
        ...state,
        orders: [action.order, ...state.orders],
        errored: null,
        loading: false,
        total: state.total + 1
      };

    case actionTypes.ORDER_DELETE:
      return {
        ...state,
        orders: [...state.orders.filter(o => o.id !== action.id)],
        errored: null,
        loading: false,
        total: state.total - 1
      };

    case actionTypes.ORDER_ERRORED:
      return {
        ...state,
        errored: action.message,
        loading: false
      };

    default:
      return {
        ...state
      };
  }
};

export default orderReducer;
