import * as actionTypes from 'actions';

const initialState = {
    store: null,
    error: null,
    loading: false,
};

const storeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_GET_INFO: {
            return {
                store: action.store,
                error: null,
                loading: false,
            };
        }

        case actionTypes.STORE_ERRORED: {
            return {
                ...state,
                error: action.message,
                loading: false,
            };
        }

        case actionTypes.STORE_ACCEPT_ERROR: {
            return {
                ...state,
                error: null,
            };
        }

        case actionTypes.STORE_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        default: {
            return state;
        }
    }
};

export default storeReducer;