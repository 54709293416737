import axios2 from 'utils/axios2';

export const R_CUSTOMER_ADD = 'R_CUSTOMER_ADD';
export const R_CUSTOMER_GET = 'R_CUSTOMER_GET';
export const R_CUSTOMER_EDIT = 'R_CUSTOMER_EDIR';
export const R_CUSTOMER_DELETE = 'R_CUSTOMER_DELETE';
export const R_CUSTOMER_LOAD = 'R_CUSTOMER_LOAD';
export const R_CUSTOMER_ERRORED = 'R_CUSTOMER_ERRORED';

/**
 *
 * @param {{
 * name: string;
 * mobile: string;
 * }} data
 * @returns {*}
 */
export const addRetail = data => dispatch => {
  dispatch({ type: R_CUSTOMER_LOAD });
  axios2
    .post('/customer', {...data, type: 'retail'})
    .then(res => dispatch({ type: R_CUSTOMER_ADD, customer: res.data }))
    .catch(error => dispatch({ type: R_CUSTOMER_ERRORED, message: error.message }));
};

/**
 *
 * @param {{
 * offset: number;
 * limit: number;
 * }} query
 * @param {string | undefined} q
 * @returns {*}
 */
export const getRetails = (query, q) => dispatch => {
  dispatch({ type: R_CUSTOMER_LOAD });
  axios2
    .get('/customer/retail', { params: { ...query, q } })
    .then(res => dispatch({ type: R_CUSTOMER_GET, ...res.data.customers }))
    .catch(error => dispatch({ type: R_CUSTOMER_ERRORED, message: error.message }));
};

/**
 *
 * @param {string} id
 * @param {{
 * name: string;
 * mobile: string;
 * }} data
 * @returns {*}
 */
export const editRetail = (id, data) => dispatch => {
  dispatch({ type: R_CUSTOMER_LOAD });
  axios2
    .put('/customer/' + id, data)
    .then(() => dispatch({ type: R_CUSTOMER_EDIT, id, data }))
    .catch(error => dispatch({ type: R_CUSTOMER_ERRORED, message: error.message }));
};

export const deleteRetail = id => dispatch => {
  dispatch({ type: R_CUSTOMER_LOAD });
  axios2
    .delete('/customer/' + id)
    .then(() => dispatch({ type: R_CUSTOMER_DELETE, id }))
    .catch(error => dispatch({ type: R_CUSTOMER_ERRORED, message: error.message }));
};
