import axios2 from 'utils/axios2';
import Cookies from 'js-cookie';

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_ERROR = 'SESSION_ERROR';
export const SESSION_LOADING = 'SESSION_LOADING';
export const SESSION_ACCEPT_ERROR = 'SESSION_ACCEPT_ERROR';

export const loginSuccess = user => ({
  type: SESSION_LOGIN,
  user
});

export const sessionFail = message => ({
  type: SESSION_ERROR,
  message
});

export const gotSessionError = () => ({
  type: SESSION_ACCEPT_ERROR
});

export const logout = () => dispatch =>
  dispatch({
    type: SESSION_LOGOUT
  });

export const login = (userId, password) => dispatch => {
  dispatch({ type: SESSION_LOADING });
  axios2
    .post('/auth/login', { userId, password })
    .then(res => dispatch(loginSuccess(res.data)))
    .catch(error => dispatch(sessionFail(error.message)));
};

/**
 *
 * @param {{
 *  name: string;
 *  password: string;
 *  storeName: string;
 *  mobile: string;
 *  email: string;
 *  country: string;
 *  address: string;
 *  licence?: string;
 *  gst?: string;
 *  logo?: Blob;
 * }} data
 */
export const register = data => dispatch => {
  const cw_user = Cookies.get('cw_user');
  if (!cw_user) {
    dispatch(sessionFail('Log in to Centum World'));
    return;
  }
  const formData = new FormData();
  formData.append('id', JSON.parse(cw_user).id);
  formData.append('name', JSON.parse(cw_user).id);
  formData.append('password', data.password);
  formData.append('storeName', data.storeName);
  formData.append('mobile', data.mobile);
  formData.append('email', data.email);
  formData.append('country', data.country);
  formData.append('address', data.address);
  if (!!data.licence) formData.append('licence', data.licence);
  if (!!data.gst) formData.append('gst', data.gst);
  if (!!data.logo) formData.append('logo', data.logo);

  dispatch({ type: SESSION_LOADING });
  axios2
    .post('/auth/admin-register', formData)
    .then(res => dispatch(loginSuccess(res.data)))
    .catch(error => dispatch(sessionFail(error.message)));
};
