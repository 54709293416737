import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { AuthGuard, Page, StoreGuard } from 'components';
import {
  Header,
  LatestProjects,
  NewProjects,
  RealTime,
  RoiPerCustomer,
  TeamTasks,
  TodaysMoney,
  SystemHealth,
  PerformanceOverTime
} from './components';
import axios2 from 'utils/axios2';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const DashboardDefault = () => {
  const classes = useStyles();
  const [data, setData] = React.useState({
    totalPaidBill: 'Loading...',
    totalUnpaidBill: 'Loading...',
    totalCredit: 'Loading...',
    totalRetail: 'Loading...',
    totalWholesale: 'Loading...',
    todaysSale: 'Loading...'
  });

  React.useEffect(() => {
    axios2.get('/dashboard').then(res =>
      setData({
        totalCredit: res.data.totalCredit.toFixed(2),
        totalPaidBill: res.data.totalPaidBill.toFixed(2),
        totalUnpaidBill: res.data.totalUnpaidBill.toFixed(2),
        totalRetail: res.data.totalRetail.toFixed(2),
        totalWholesale: res.data.totalWholesale.toFixed(2),
        todaysSale: res.data.todaysSale.toFixed(2)
      })
    );
  }, []);

  return (
    <AuthGuard roles={['admin']}>
      <StoreGuard plans={['basic', 'premium']}>
        <Page className={classes.root} title="Default Dashboard">
          <Header />
          <Grid className={classes.container} container spacing={3}>
            <Grid item lg={4} sm={6} xs={12}>
              <TodaysMoney value={data.totalPaidBill} title="Total Paid Bill" />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <NewProjects
                value={data.totalUnpaidBill}
                title="Total Due Bill"
              />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <RoiPerCustomer value={data.totalCredit} title="Total Credit" />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TodaysMoney value={data.totalRetail} title="Retail Sales" />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <NewProjects value={data.totalWholesale} title="Wholesales" />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <RoiPerCustomer value={data.todaysSale} title="Today's Sales" />
            </Grid>
            <Grid item lg={5} xl={4} xs={12}>
              <TeamTasks />
            </Grid>
            <Grid item lg={7} xl={8} xs={12}>
              <LatestProjects />
            </Grid>
          </Grid>
        </Page>
      </StoreGuard>
    </AuthGuard>
  );
};

export default DashboardDefault;
