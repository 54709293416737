import * as actionTypes from 'actions';
import Cookies from 'js-cookie';

const initialState = {
  user: null,
  loading: false,
  error: null
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      Cookies.set('cwplus_user', action.user, {domain: process.env.REACT_APP_COOKIE_DOMAIN});
      return {
        user: action.user,
        loading: false,
        error: null
      };
    }

    case actionTypes.SESSION_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.SESSION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.message
      };
    }

    case actionTypes.SESSION_ACCEPT_ERROR: {
      return {
        ...state,
        error: null
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      Cookies.remove('cwplus_user', {domain: process.env.REACT_APP_COOKIE_DOMAIN});
      return {
        user: null,
        loading: false,
        error: null
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
