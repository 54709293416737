import axios2 from 'utils/axios2';

export const VENDOR_ADD = 'VENDOR_ADD';
export const VENDOR_GET = 'VENDOR_GET';
export const VENDOR_EDIT = 'VENDOR_EDIR';
export const VENDOR_DELETE = 'VENDOR_DELETE';
export const VENDOR_LOAD = 'VENDOR_LOAD';
export const VENDOR_ERRORED = 'VENDOR_ERRORED';

/**
 *
 * @param {{
 * name: string;
 * mobile: string;
 * gstin: string;
 * }} data
 * @returns {*}
 */
export const addVendor = data => dispatch => {
  dispatch({ type: VENDOR_LOAD });
  axios2
    .post('/vendor', data)
    .then(res => dispatch({ type: VENDOR_ADD, vendor: res.data }))
    .catch(error => dispatch({ type: VENDOR_ERRORED, message: error.message }));
};

/**
 *
 * @param {{
 * offset: number;
 * limit: number;
 * }} query
 * @param {string | undefined} q
 * @returns {*}
 */
export const getVendors = (query, q) => dispatch => {
  dispatch({ type: VENDOR_LOAD });
  axios2
    .get('/vendor', { params: { ...query, q } })
    .then(res => dispatch({ type: VENDOR_GET, ...res.data.vendors }))
    .catch(error => dispatch({ type: VENDOR_ERRORED, message: error.message }));
};

/**
 *
 * @param {string} id
 * @param {{
 * name: string;
 * mobile: string;
 * gstin: string;
 * }} data
 * @returns {*}
 */
export const editVendor = (id, data) => dispatch => {
  dispatch({ type: VENDOR_LOAD });
  axios2
    .put('/vendor/' + id, data)
    .then(() => dispatch({ type: VENDOR_EDIT, id, data }))
    .catch(error => dispatch({ type: VENDOR_ERRORED, message: error.message }));
};

export const deleteVendor = id => dispatch => {
  dispatch({ type: VENDOR_LOAD });
  axios2
    .delete('/vendor/' + id)
    .then(() => dispatch({ type: VENDOR_DELETE, id }))
    .catch(error => dispatch({ type: VENDOR_ERRORED, message: error.message }));
};
