import * as actionTypes from '../actions';

const initialState = {
  transactions: [],
  filters: {},
  errored: null,
  loading: false,
  page: 0,
  rowCount: 10,
  total: 0
};

/**
 *
 * @returns {{
 *  transactions: any[];
 *  filters: {[key: string]: any[]};
 *  errored: string | null;
 *  loading: boolean;
 *  page: number;
 *  rowCount: number;
 *  total: number;
 * }}
 */
const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRANSACTION_LOAD:
      return {
        ...state,
        loading: true
      };

    case actionTypes.TRANSACTION_GET:
      return {
        transactions: action.items,
        filters: action.filters || {},
        errored: null,
        loading: false,
        page: Math.floor(action.offset / action.limit),
        rowCount: action.limit,
        total: action.total
      };

    case actionTypes.TRANSACTION_ERROR:
      return {
        ...state,
        errored: action.message,
        loading: false
      };

    default:
      return {
        ...state
      };
  }
};

export default transactionReducer;
