import axios2 from 'utils/axios2';

export const STORE_GET_INFO = 'STORE_GET_INFO';
export const STORE_LOADING = 'STORE_LOADING';
export const STORE_ERRORED = 'STORE_ERRORED';
export const STORE_ACCEPT_ERROR = 'STORE_ACCEPT_ERROR';

export const acceptStoreError = () => ({
  type: STORE_ACCEPT_ERROR
});

export const getStoreInfo = () => dispatch => {
  dispatch({ type: STORE_LOADING });
  axios2
    .get('/store')
    .then(res => dispatch({ type: STORE_GET_INFO, store: res.data }))
    .catch(error => dispatch({ type: STORE_ERRORED, message: error.message }));
};
