import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import productReducer from './productReducer';
import vendorReducer from './vendorReducer';
import orderReducer from './orderReducer';
import retailCustomerReducer from './retailCustomerReducer';
import wsaleCustomerReducer from './wsaleCustomerReducer';
import retailInvoiceReducer from './retailInvoiceReducer';
import wsaleInvoiceReducer from './wsaleInvoiceReducer';
import transactionReducer from './transactionReducer';
import storeReducer from './storeReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  store: storeReducer,
  product: productReducer,
  vendor: vendorReducer,
  order: orderReducer,
  retail: retailCustomerReducer,
  wholesale: wsaleCustomerReducer,
  rInvoice: retailInvoiceReducer,
  wInvoice: wsaleInvoiceReducer,
  transaction: transactionReducer,
});

export default rootReducer;
